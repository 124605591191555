import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private info = new BehaviorSubject<boolean>(false);
  public role = new BehaviorSubject<any>({});

  private currentUser;
  private currentToken;
  private currentKey;

  currentState = this.info.asObservable();

  guest() {

    if (this.token() && this.user()) {
      this.state(false);
      return false;
    }

    this.state(true);
    return true;
  }

  user() {
    let user = localStorage.getItem('_currentTachableUser');

    return JSON.parse(user);
  }

  state(state: boolean) {
    this.info.next(state);
  }

  token() {
    return localStorage.getItem('authTachabletoken') || '';
  }

  login(data: any, remember = false) {
    this.state(true);
    this.currentUser = data.data;
    this.currentToken = data.token;

    let user = JSON.stringify(data.data);
    let token = data.token;

    localStorage.setItem('_currentTachableUser', user);
    localStorage.setItem('authTachabletoken', token);

  }

  logout() {

    // remove local storage
    localStorage.removeItem('_currentTachableUser');
    localStorage.removeItem('authTachabletoken');
  }
}
