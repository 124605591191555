import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToasterService } from './toaster.service';


@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  apiReq: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    private toastService: ToasterService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // document.getElementById('CustomLoader').innerHTML = `<div id="preloader"><div id="loader"></div></div>`;

    let apiReq = req.clone({
      url: `${environment.baseUrl}/${req.url}`,
    });

    if (!this.auth.guest()) {
      apiReq = apiReq.clone(
        {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.auth.token(),
            'webToken': 'Bearer ' + this.auth.token()
          })
        });
        console.log('Bearer ' + this.auth.token());
    }


    return next.handle(apiReq).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        // document.getElementById('CustomLoader').innerHTML = '';
        if (event.body.message) {
          this.toastService.show('success', '', '' + event.body.message + '');
        }
      }
    },
      error => {
        if (error.status === 422) {
          this.toastService.show('error', 'Error!', '' + 'Please correct the highlighted inputs.' + '');
          return;
        }

        if (error.status === 406) {
          this.toastService.show('error', 'Error!', '' + error.error.message + '' || 'Internal server error');
          return;
        }

        if (error.error) {
          this.toastService.show('error', 'Error!', '' + error.error.message + '' || 'Internal server error');
        }
        if ((error.status === 401)) {
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate([''], { queryParams: { ref: 'user' } });
          }, 500);
          this.toastService.show('error', 'Error!', '' + error.error.message + '' || 'Internal server error');
          return;
        }
      }));
  }
}
