import { Component } from '@angular/core';
import { ToasterService } from './services/toaster.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { LoaderService } from './services/loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TachableSuperAdmin';
  loading = false;
  constructor(private toaster: ToasterService, private loader: LoaderService, private router: Router) { }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loader.state(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loader.state(false);
      }
    });
  }

  ngAfterViewInit() {
    this.loader.attach.subscribe(data => {
      setTimeout(() => {
        this.loading = data;
      }, 100);
    });
  }
}
