import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../services/toaster.service';
import { Toast } from './toast.interface';

@Component({
  selector: 'app-toaster',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToasterComponent implements OnInit {
  toasts: Toast[] = [];
  @Input() toast: Toast;
  @Input() i: number;
  @Output() removee = new EventEmitter<number>();

  constructor(
    private toaster: ToasterService
  ) { }

  ngOnInit() {

    this.toaster.toast$
      .subscribe(toast => {
        this.toasts = [toast, ...this.toasts];
        setTimeout(() => this.toasts.pop(), toast.delay || 5000);
      });
  }

  remove(index: number) {
    this.toasts = this.toasts.filter((v, i) => i !== index);
  }

}
